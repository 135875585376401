.item-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
  text-align: center;
  grid-gap: 10px;
  width: 100%;
  height: 400px; /* Adjust this height based on your needs */
  overflow-y: auto;
  border: 1px dashed #ccc;
}

.item {
  width: 150px;
  height: 180px; /* Adjust this height based on your needs */
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  overflow: hidden;
}
.calender{
padding-left: 10px;}
.item img {
width: 150px;
  height: 150px;
  border-radius:100%;
  object-fit: cover;
}
.diagnosis-image img {
  width: 300px;
    height: 300px;
    border-radius:100%;
    object-fit: cover;
  }
/* App.css */
.chart-container {
  display: flex;
  height: 100%; /* Full viewport height for demonstration */
  width: 100%;
}

.chart-wrapper {
  flex: 1; /* Take up all available space */
}
.checkup-title{
  grid-template-rows: max-content;
}

