* {
    box-sizing: border-box;
  }
  #image {
    text-align: left;
    justify-self: left;
  }
  .seven h1 {
    text-align: center;
    font-weight: 300;
    color: #222;
    letter-spacing: 1px;
    text-transform: uppercase;
  
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
  }
  
  .seven h1:after,
  .seven h1:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #0064b6;
    border-top: 1px solid #0064b6;
    height: 5px;
    background-color: #f8f8f8;
  
  
  }
  
  