form {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  button {
    padding: 8px 12px;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
