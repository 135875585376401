.success{
    border:2px solid var(--primary-color) !important;
}
.error{
    border:2px solid   #B3CCD8;
} 
.button-disable {
    border:2px solid   #05a7f2;
}

