    /* ============================== Login-Register Style ============================== */
    .login-register {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      background-color: #B3CCD8;
      position: relative;
      overflow: hidden;
    }

    /*.login-register::after {
      content: "";
      position: absolute;
      width: 150%;
      height: 380px;
      background-color: #7ba2b3;  
      z-index: -1;
      transform: rotate(+30deg);
    }*/
    .login {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 4rem ;
      padding: 5rem;
      background-color: var(--white-color);
      box-shadow: 0 6px 20px rgba(12, 158, 141, 0.3);
      border-radius: 40px;
      height: max-content;
      border-bottom: 10px solid #7ba2b3;
    }
   c
    .login__title {
      display: block;
      color: #7b868a;
      font-size: 2.2rem;
    }
    .login__subtitle {
      display: block;
      color: #7b868a;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
    .login__new-member {
      background-color: #f0f2f7;
      margin: 1.5rem 0;
      border-radius: 5px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 0;
    }
    .login__new-member-text {
      color: #7b868a;
      font-size: 1.4rem;
    }
    .login__new-member-link {
      background-color: #a8aaaf;
      color: var(--white-color);
      font-size: 1.2rem;
      margin-right: 0.6rem;
      border-radius: 5px;
      padding: 0.4rem 0.8rem;
    }
    .login__new-member-link:hover {
      color: var(--white-color);
    }

    .login-form__username,
    .login-form__password {
      position: relative;
    }
    .login-form__username-input,
    .login-form__password-input {
      width: 100%;
      border: 1px solid #e6e6e6;
      padding: 1rem 1.1rem;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
      margin: 0.8rem 0;
      border-radius: 20px;
      font-size: 1.4rem;
    }
    .login-form__username-icon, 
    .login-form__password-icon {
      position: absolute;
      right: 1.5rem;
      top: 2rem;
      font-size: 2.2rem;
     
    }
    .login-form__btn-selected {
      background-color: #92dcfe;
    }
    .login-form__btn-success {
      background-color: #388E3C;
    }
    .login-form__btn-error {
      background-color: #D50000;
    }
    .login-form__btn {
      cursor: pointer;
      border: none;
      border-radius: 23px;
      padding: 1.2rem 0;
      margin-top: 0.8rem;
      display: flex;
      align-items: center;
      position: relative;
    }
    .login-form__btn-icon {
      color: var(--white-color);
      font-size: 1.8rem;
      position: absolute;
      right: 1.5rem;
    }
    .login-form__btn-text {
      color: var(--white-color);
      margin: 0 auto;
    }
    .login-form__password-setting {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
    }
    .login-form__password-remember {
      display: flex;
      align-items: center;
    }
    .login-form__password-checkbox {
      margin-left: 1rem;
    }
    .login-form__password-text {
      color: #6c757d;
      font-size: 1.4rem;
    }
    .login-form__password-forget-link {
      color: #6c757d;
      font-size: 1.4rem;
    }
    .login__des {
      display: none;
      margin-top: 3rem;
      color: #7b868a;
      font-size: 1.2rem;
      width: 100%;
    }
    @media (min-width: 450px) {
      .login__des{
        display: block;
      }
    
      
  }
    .login__des-list {
      list-style-type: disc;
      margin-right: 2rem;
      margin-top: 1rem;
    }
    /* ============================== Login-Register Style ============================== */
    .swal-footer{
      display: flex;
      justify-content: center;
    }
    .recaptcha-parent {
      margin-top:  2rem;
    }