
/* Start Helper Classes */

.title {
    font-size: 2.3rem;
    color: #444446;
    font-weight: bold;
    line-height: 1.2;
    position: relative;
  }
  
  .title::before {
    content: "";
    position: absolute;
    right: -1rem;
    width: 0.4rem;
    height: 4.4rem;
    background-color: var(--primary-color);
    border-radius: 5rem;
    transform: rotate(8deg);
  }
  
  .title::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    /* max-width: 250px; */
    height: 60%;
    background-color: rgba(43, 206, 86, 0.2);
  }
  /* Finish Helper Classes */