
/* Start users Pagination Styles */

.users-pagination {
    margin: 3rem 0;
}

.users__pagination-list {
    display: flex;
    align-items: center;
    justify-content: center ;
}

.users__pagination-link {
        width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background-color: #f0f0f1;
    margin: 0 0.5rem;
}

.users__pagination-link:hover {
    color: var(--white-color);
    background-color: #92dcfe;
}

.users__pagination-link--active {
    color: var(--white-color);
    background-color: #005277;
}

/* Finish users Pagination Styles */